import React from 'react';

import { theme } from 'styles/theme';

import { AppBar, Box, Container, Toolbar } from '@mui/material';

import { Typography } from 'components/base/Typography';

import { Logo } from 'assets/images';

import boxBackground from '../assets/images/boxBackground.png';

export function BlankPage({ image, title, content, contentBold, actions }) {
  return (
    <>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
          borderBottom: '2.5px solid',
          borderImage: `linear-gradient(to right, ${theme.palette.primary.dark} 30%, ${theme.palette.secondary.main} 20%)`,
          borderImageSlice: '1',
        }}
      >
        <Container sx={{ width: '100%', maxWidth: '100% !important' }}>
          <Toolbar disableGutters>
            <Box
              display="flex"
              sx={{ cursor: 'pointer' }}
              role="button"
              onClick={() => {}}
              onKeyDown={() => {}}
              tabIndex={0}
            >
              <Logo />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        width="100%"
        sx={{
          width: '100%',
          height: '91vh',
          overflow: 'hidden',
          backgroundImage: `url(${boxBackground})`,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        flexDirection="column"
      >
        <Box display="">
          {image}
          <Box mb="16px">
            <Typography fS="24px" fW="700" color="#003F71">
              {title}
            </Typography>
          </Box>
          <Box sx={{ padding: '0px 30px' }}>
            <Typography fS="18px" fW="400" color="#000000" lH="27px">
              {content}
            </Typography>
          </Box>

          <Typography fS="18px" fW="700" color="#000000" lH="27px">
            {contentBold}
          </Typography>

          {actions}
        </Box>
      </Box>
    </>
  );
}
