import Api from 'clients/api/Api';
import { UserManagerSettings } from 'oidc-client-ts';

import { LocalStorage, SOTER_STORAGE_KEY } from 'utils/storage/localStorage';

import {
  CreateOrUpdateUserParams,
  GetUserByTokenResponse,
  GetUsersParams,
  GetUsersResponse,
} from 'domains/user';
import { ImportResponse } from 'domains/common';

export const oidcConfig = {
  authority: process.env.REACT_APP_SOTER_AUTHORITY,
  client_id: process.env.REACT_APP_SOTER_CLIENT_ID,
  redirect_uri: `${window.location.origin}/signin-oidc`,
  response_type: 'code',
  scope: 'openid profile email',
  automaticSilentRenew: true,
} satisfies UserManagerSettings;

const currentUser = async (): Promise<GetUserByTokenResponse> => {
  const user = LocalStorage.getValueOf(`${SOTER_STORAGE_KEY}:User`) as GetUserByTokenResponse;

  if (!user) window.location.href = '/signout';

  return user;
};

const getCurrentUser = async (): Promise<GetUserByTokenResponse> => {
  const { data } = await Api.get({ url: '/User/Auth' });

  LocalStorage.setValue(`${SOTER_STORAGE_KEY}:User`, data, true);

  return data;
};

const getAllUsers = async ({
  searchFilter = '',
  plantId = '',
  roles = [],
  currentPage = 1,
  pageSize = 20,
}: GetUsersParams): Promise<GetUsersResponse> => {
  const rolesParam =
    roles && roles.length > 0 ? roles.map(role => `roles=${role}`).join('&') : 'roles=';

  const { data } = await Api.get({
    url: `/User?${rolesParam}`,
    params: {
      searchFilter,
      plantId,
      currentPage,
      pageSize,
    },
  });

  return data;
};

const createUser = async (body: CreateOrUpdateUserParams): Promise<void> => {
  const { data } = await Api.post({ url: '/User', body });

  return data;
};

const updateUser = async ({ id, ...body }: CreateOrUpdateUserParams): Promise<void> => {
  await Api.put({ url: `/User/${id}`, body });
};

const importUsers = async (formData: FormData): Promise<ImportResponse> => {
  const response = await Api.post({
    url: `/User/Import/Buyer`,
    body: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  return response.data;
};

export const UserService = {
  currentUser,
  getCurrentUser,
  getAllUsers,
  createUser,
  updateUser,
  importUsers,
};
