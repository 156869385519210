import Api from 'clients/api/Api';

import { ImportResponse } from 'domains/common';
import {
  ProductByIdResponse,
  GetProductsResponse,
  GetProductParams,
  UpdateProductParams,
} from 'domains/product';

const getProductsData = async ({
  searchFilter = '',
  plantId,
  currentPage = 1,
  pageSize = 20,
}: GetProductParams): Promise<GetProductsResponse> => {
  return Api.get({
    url: '/Product',
    params: {
      searchFilter,
      plantId,
      currentPage,
      pageSize,
    },
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

async function getProductsList(id: string): Promise<GetProductsResponse> {
  return Api.get({
    url: '/Product',
    params: { plantId: id, currentPage: 1, pageSize: 999999 },
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
}

const getProductByIdData = async (productId: string): Promise<ProductByIdResponse> => {
  return Api.get({
    url: `/Product/${productId}`,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const importProducts = async (
  formData: FormData,
): Promise<ProductByIdResponse & ImportResponse> => {
  return Api.post({
    url: `/Product/Import`,
    body: formData,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const updateProduct = async (data: UpdateProductParams): Promise<void> => {
  const url = `/Product/${data.id}`;

  delete data.id;

  return Api.put({
    url,
    body: data,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

export const ProductService = {
  getProductsData,
  getProductsList,
  getProductByIdData,
  importProducts,
  updateProduct,
};
