/* eslint-disable no-console */
import Api from 'clients/api/Api';

import {
  CreateOrUpdatePlantParams,
  GetPlantByIdParams,
  GetPlantByIdResponse,
  GetPlantParams,
  GetPlantResponse,
} from 'domains/plant';

const getPlantsData = async ({
  searchFilter = '',
  currentPage = 1,
  pageSize = 20,
}: GetPlantParams): Promise<GetPlantResponse> => {
  return Api.get({
    url: '/Plant',
    params: {
      searchFilter,
      currentPage,
      pageSize,
    },
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const getPlantById = async ({ id }: GetPlantByIdParams): Promise<GetPlantByIdResponse> => {
  return Api.get({
    url: `/Plant/${id}`,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const createPlant = async (data: CreateOrUpdatePlantParams): Promise<void> => {
  delete data.id;

  return Api.post({
    url: '/Plant',
    body: data,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const updatePlant = async (data: CreateOrUpdatePlantParams): Promise<void> => {
  const url = `/Plant/${data.id}`;

  delete data.id;

  return Api.put({
    url,
    body: data,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

export const PlantService = {
  getPlantsData,
  getPlantById,
  createPlant,
  updatePlant,
};
