import { Customer } from 'domains/customer';

interface CustomerCompanyState {
  isLoading?: boolean;
  currentPage?: number;
  totalPages?: number;
  totalItems?: number;
  items?: Customer[];
}

const customerCompanyInitialState: CustomerCompanyState = {
  isLoading: false,
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  items: [],
};

export { CustomerCompanyState, customerCompanyInitialState };
