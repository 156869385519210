/* eslint-disable react/no-danger */
import React from 'react';

import { Typography as Ty, TypographyProps } from '@mui/material';

/**
 * @param  - - `tT`: A string value indicating the text tranfsform. Default value is
 * `initial`.
 * @param  - - `fS`: A string value indicating the font size. Default value is
 * `14px`.
 * @param  - - `fStyle`: A string value indicating the font style. Default value is
 * `normal`.
 * @param  - - `fW`: A string value indicating the font weight. Default value is
 * `400`.
 * @param  - - `lH`: A number value indicating the line weight. Default value is `150%`.
 * @param  - - `lS`: A string value indicating the letter spacing. Default value is`0.15px`.
 * @param  - - `variant`: A string value indicating the theme typography style. Default value is`22px`.
 * @param  - - `display`: A string value indicating the value of font weight. Default value is`button`.
 * @param  - - `color`: A string value indicating the font color.
 *  Default value is`black`.
 * @param  - - `children`: A node that is showed inside the element.
 * @returns a JSX element.
 */
export function Typography({
  tT = 'initial',
  tA = 'unset',
  fS = '14px',
  fStyle = 'normal',
  fW = '400',
  lH = '150%',
  lS = '0.15px',
  variant = 'button',
  display = 'block',
  color = 'black',
  children,
}): JSX.Element {
  return (
    <Ty
      component="span"
      sx={{
        textTransform: tT,
        textAlign: tA,
        fontSize: fS,
        fontWeight: fW,
        lineHeight: lH,
        letterSpacing: lS,
        fontStyle: fStyle,
      }}
      variant={variant as TypographyProps['variant']}
      display={display}
      color={color}
    >
      <span data-testid="span-typography" dangerouslySetInnerHTML={{ __html: children }} />
    </Ty>
  );
}
