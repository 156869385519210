import Api from 'clients/api/Api';

export interface DownloadFileProps {
  url: string;
  nameFile: string;
  method?: 'GET' | 'POST';
  body?: BodyInit;
  headers?: HeadersInit;
}

export const downloadFile = async ({
  url,
  body,
  headers,
  method = 'GET',
  nameFile,
}: DownloadFileProps) => {
  const response = await fetch(url, { method, body, headers });
  const blob = await response.blob();

  const urlFile = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = urlFile;

  link.setAttribute('download', nameFile);
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(urlFile);
};

const exportFile = async (url: string, fileName: string) => {
  const { data } = await Api.post({ url });

  const link = document.createElement('a');
  link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=ISO-8859-1;base64, ${data}`;

  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const DownloadFileService = {
  downloadFile,
  exportFile,
};
