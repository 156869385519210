export const SOTER_STORAGE_KEY = '@WestrockSheetplant';

/**
 * The function `getValueOf` retrieves the value of a given key from local storage and optionally
 * parses it as JSON if specified.
 * @param {string} key - The `key` parameter is a string that represents the key used to retrieve the
 * value from the localStorage.
 * @param [isObject=false] - The `isObject` parameter is a boolean flag that indicates whether the
 * value stored in the `localStorage` should be parsed as a JSON object or returned as a string. By
 * default, it is set to `false`, which means the value will be returned as a string. If you pass
 * `true`
 * @returns The function `getValueOf` returns the value of the specified key from the localStorage. If
 * the `isObject` parameter is set to `false` (or not provided), it returns the value as a string. If
 * `isObject` is set to `true`, it parses the value as JSON and returns the parsed object.
 */
const getValueOf = (key: string, isObject = true) => {
  const data = localStorage.getItem(key);
  return !isObject ? data : JSON.parse(data);
};

/**
 * The function `setValue` stores a value in the browser's local storage, optionally converting it to a
 * JSON string if it is an object.
 * @param key - The key parameter is a string that represents the name of the item you want to store in
 * the localStorage. It is used as a unique identifier for the item.
 * @param value - The `value` parameter is the value that you want to store in the local storage. It
 * can be of any data type, such as a string, number, boolean, array, or object.
 * @param [isObject=false] - The `isObject` parameter is a boolean flag that indicates whether the
 * `value` parameter should be treated as an object or not. If `isObject` is set to `true`, the `value`
 * parameter will be converted to a JSON string using `JSON.stringify()` before storing it in the
 * localStorage.
 */
const setValue = (key, value, isObject = false) => {
  const data = !isObject ? value : JSON.stringify(value);
  localStorage.setItem(key, data);
};

/**
 * The function `getValueOf` retrieves the value of a given key from local storage and optionally
 * parses it as JSON if specified.
 * @param {string} key - The `key` parameter is a string that represents the key used to retrieve the
 * value from the localStorage.
 * @returns The function `removeValueOf` remove the value of the specified key from the localStorage.
 */
const removeValueOf = (key: string) => {
  localStorage.removeItem(key);
};

export const LocalStorage = { getValueOf, setValue, removeValueOf };
