import { Customer } from 'domains/customer';
import { Plant } from 'domains/plant';

export interface SoterUserProfile {
  s_hash: string;
  email: string;
  sid: string;
  sub: string;
  auth_time: number;
  idp: string;
  name: string;
  given_name: string;
  family_name: string;
  locale: string;
  preferred_username: string;
  amr: Array<string>;
}

export interface SoterUser {
  access_token: string;
  id_token: string;
  session_state: string;
  token_type: string;
  scope: string;
  profile: SoterUserProfile;
  expires_at: number;
  customers?: Customer[];
}

export interface GetUserByTokenResponse {
  id: string;
  name: string;
  email: string;
  soterId: string;
  userId: string;
  role: UserRoles;
  plant: Plant;
  isAdmin: boolean;
  createdAt: string;
  updatedAt: string;
}

export type User = SoterUser & GetUserByTokenResponse & { navigation: string };

export enum UserRoles {
  Buyer = 'Buyer',
  Analyst = 'Analyst',
  Admin = 'Admin',
}

export interface GetUsersParams {
  searchFilter?: string;
  plantId?: string;
  roles?: UserRoles[];
  currentPage?: number;
  pageSize?: number;
}

export interface GetUsersResponse {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  items: User[];
}

export interface CreateOrUpdateUserParams {
  id?: string;
  soterId?: string;
  name: string;
  email: string;
  role: string;
  plantId: string;
}

export interface ImportUsersParams {
  file: File;
}
