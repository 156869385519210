import React from 'react';

import { Button as Btn } from '@mui/material';

/**
 * @param  - - `w`: width of the button (default: '100%')
 * @returns The `Button` component is being returned as a JSX element.
 */
export function Button({
  w = '100%',
  mxW = '100%',
  mnW = 'unset',
  sI = false,
  eI = false,
  icon = null,
  mT = 2,
  mB = 0,
  mR = '0px',
  color = '#000000DE',
  d = 'flex',
  fS = '13px',
  lH = '22px',
  fW = '400',
  backgroundH = 'transparent',
  borderH = 'unset',
  tT = 'none',
  tDL = 'none',
  bC = 'unset',
  v = 'text',
  pd = '6px 8px,',
  disabled = false,
  testId = '',
  sx = {},
  children,
  onClick = () => {},
}): JSX.Element {
  if (sI && eI) {
    console.warn(
      'Warning: Both `sI` and `eI` props cannot be true simultaneously. Icon prop will be ignored.',
    );
  }

  return (
    <Btn
      variant={v as 'text' | 'outlined' | 'contained'}
      sx={{
        width: w,
        maxWidth: mxW,
        minWidth: mnW,
        backgroundColor: disabled ? '#D1D1D1' : bC,
        my: 2,
        mr: mR,
        color: disabled ? '#686868' : color,
        display: d,
        fontSize: fS,
        lineHeight: lH,
        fontWeight: fW,
        marginTop: mT,
        marginBottom: mB,
        textTransform: tT,
        textDecorationLine: tDL,
        padding: pd,
        '&:hover': {
          backgroundColor: backgroundH,
          border: borderH,
        },
        ...sx,
      }}
      onClick={onClick}
      startIcon={sI && !eI && icon}
      endIcon={!sI && eI && icon}
      disabled={disabled}
      data-testid={testId}
    >
      {children}
    </Btn>
  );
}
