import { createStore } from 'effector';

import {
  customerCompanyEvent,
  customerCompanyEventDone,
  customerCompanyEventFail,
} from './CustomerCompanyEvents';
import { CustomerCompanyState, customerCompanyInitialState } from './CustomerCompanyState';

const customerCompanyStore = createStore<CustomerCompanyState>(customerCompanyInitialState)
  .on(customerCompanyEvent, state => ({ ...state, isLoading: true }))
  .on(customerCompanyEventDone, (state, payload) => {
    return {
      ...state,
      ...payload,
      isLoading: false,
    };
  })
  .on(customerCompanyEventFail, state => ({
    ...state,
    customerCompaniesList: null,
    isLoading: false,
  }));

export { customerCompanyStore };
