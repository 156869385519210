import {
  customerCompanyEvent,
  customerCompanyEventDone,
  customerCompanyEventFail,
} from 'store/CustomerCompany';

import { GetCustomerValues } from 'domains/customer';

const execute = async ({ currentPage, totalItems, totalPages, items }: GetCustomerValues) => {
  try {
    customerCompanyEvent({ isLoading: true });

    customerCompanyEventDone({ isLoading: false, currentPage, totalItems, totalPages, items });
    return items;
  } catch (error: any) {
    customerCompanyEventFail({ isLoading: false });
    return null;
  }
};

const storeCustomerCompanyUseCase = {
  execute,
};

export { storeCustomerCompanyUseCase };
