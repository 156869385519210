import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { theme } from 'styles/theme';

import { Box, Button, CircularProgress } from '@mui/material';

import { BlankPage } from 'components/BlankPage';
import { Header } from 'components/complex/Header';

import { RoleHash } from 'utils/shared/roleHash';

import { UserRoles } from 'domains/user';

import userForbidden from '../assets/images/userForbidden.png';

export function ProtectedRoute() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const backToLogin = () => {
    navigate('/signout');
  };

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return (
        <BlankPage
          image={<CircularProgress />}
          title="Conectando"
          content=""
          contentBold=""
          actions=""
        />
      );
    case 'signoutRedirect':
      return (
        <BlankPage
          image={<CircularProgress />}
          title="Desconectando"
          content=""
          contentBold=""
          actions=""
        />
      );
    default:
  }

  if (auth.isLoading) {
    return (
      <BlankPage
        image={<CircularProgress />}
        title="Carregendo"
        content=""
        contentBold=""
        actions=""
      />
    );
  }

  if (auth.error) {
    return (
      <BlankPage
        image={<img src={userForbidden} alt="userForbidden" width="50%" />}
        title="Oops..."
        content="Ocorreu um erro ao tentar conectar sua conta"
        contentBold={auth.error.message}
        actions={
          <Button
            variant="outlined"
            sx={{
              mr: '16px',
              mt: '20px',
              mb: '20px',
              fontWeight: theme.typography.fontWeightBold,
              border: `solid 2px ${theme.palette.primary.dark}`,
              color: theme.palette.primary.dark,

              ':hover': {
                border: `solid 2px ${theme.palette.primary.dark}`,
                color: theme.palette.primary.dark,
              },
            }}
            onClick={() => backToLogin()}
          >
            Desconectar
          </Button>
        }
      />
    );
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/signin" replace />;
  }

  if (location.pathname.includes('admin') && !RoleHash.verifyRole(UserRoles.Admin))
    navigate('/forbidden');

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Header activePage="/" name={auth.user.profile.name} />
      <Outlet />
    </Box>
  );
}
