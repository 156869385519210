import React, { Suspense, lazy } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { BlankPage } from 'components/BlankPage';

import { ProtectedRoute } from './ProtectedRoute';

// Auth
const AuthSigninPage = lazy(() => import('../pages/auth/signin-page'));
const AuthSigninOidcPage = lazy(() => import('../pages/auth/signin-oidc-page'));
const AuthSignoutPage = lazy(() => import('../pages/auth/signout-page'));
const AuthForbiddenPage = lazy(() => import('../pages/auth/forbidden-page'));
const AuthUnauthorizedPage = lazy(() => import('../pages/auth/unauthorized-page'));

// App
const AdminHomePage = lazy(() => import('../pages/admin/home-page'));
const AdminProductPage = lazy(() => import('../pages/admin/product-page'));
const AdminPlantPage = lazy(() => import('../pages/admin/plant-page'));
const AdminUserPage = lazy(() => import('../pages/admin/user-page'));

// App
const AppHomePage = lazy(() => import('../pages/app/home-page'));
const AppOrderRegisterPage = lazy(() => import('../pages/app/order/register-page'));
const AppOrderEditPage = lazy(() => import('../pages/app/order/edit-page'));

export function Routes(): JSX.Element {
  return (
    <Suspense
      fallback={
        <BlankPage
          image={<CircularProgress />}
          title="Carregando"
          content=""
          contentBold=""
          actions=""
        />
      }
    >
      <Switch>
        <Route key="/signin" path="/signin" element={<AuthSigninPage />} />,
        <Route key="/signin-oidc" path="/signin-oidc" element={<AuthSigninOidcPage />} />,
        <Route key="/signout" path="/signout" element={<AuthSignoutPage />} />
        <Route key="/forbidden" path="/forbidden" element={<AuthForbiddenPage />} />
        <Route key="/unauthorized" path="/unauthorized" element={<AuthUnauthorizedPage />} />
        <Route path="/" element={<ProtectedRoute />}>
          <Route key="/" path="/" element={<AppHomePage />} />,
          <Route key="/admin" path="/admin" element={<AdminHomePage />} />,
          <Route key="/admin/product" path="/admin/product" element={<AdminProductPage />} />,
          <Route key="/admin/plant" path="/admin/plant" element={<AdminPlantPage />} />,
          <Route key="/admin/user" path="/admin/user" element={<AdminUserPage />} />,
          <Route key="/orderRegister" path="/orderRegister" element={<AppOrderRegisterPage />} />
          <Route key="/orderEdit/:id" path="/orderEdit/:id" element={<AppOrderEditPage />} />
        </Route>
      </Switch>
    </Suspense>
  );
}
