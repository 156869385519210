import { createEvent } from 'effector';

import { CustomerCompanyState } from './CustomerCompanyState';

const customerCompanyEvent = createEvent<Partial<CustomerCompanyState>>('customerCompanyEvent');
const customerCompanyEventDone = createEvent<CustomerCompanyState>('customerCompanyEventDone');
const customerCompanyEventFail = createEvent<Partial<CustomerCompanyState>>(
  'customerCompanyEventFail',
);

export { customerCompanyEvent, customerCompanyEventDone, customerCompanyEventFail };
