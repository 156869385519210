import Api from 'clients/api/Api';

import { ImportResponse } from 'domains/common';
import {
  GetCustomerByIdResponse,
  GetCustomerParams,
  GetCustomerValues,
  UpdateCustomerParams,
} from 'domains/customer';

import * as customerCompaniesUseCase from '../useCases/customers/customerCompanies/storeCustomerCompaniesUseCase';

const getCustomersData = async ({
  searchFilter = '',
  plantId,
  currentPage = 1,
  pageSize = 20,
}: GetCustomerParams): Promise<GetCustomerValues> => {
  return Api.get({
    url: '/Customer',
    params: {
      searchFilter,
      plantId,
      currentPage,
      pageSize,
    },
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

async function getCustomersList() {
  try {
    const { data } = await Api.get({
      url: '/Customer',
    });

    if (data) {
      await customerCompaniesUseCase.storeCustomerCompanyUseCase.execute(data);
    }
  } catch (e) {
    // console.error(`User not found: ${e}`);
  }
}

const getCustomerByIdData = async (customerId: string): Promise<GetCustomerByIdResponse> => {
  return Api.get({
    url: `/Customer/${customerId}`,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const updateCustomer = async (data: UpdateCustomerParams): Promise<void> => {
  const url = `/Customer/${data.id}`;

  delete data.id;

  return Api.put({
    url,
    body: data,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const importCustomers = async (formData: FormData): Promise<ImportResponse> => {
  return Api.post({
    url: `/Customer/Import`,
    body: formData,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

export const CustomerService = {
  getCustomersList,
  getCustomersData,
  getCustomerByIdData,
  updateCustomer,
  importCustomers,
};
