import Axios, { AxiosRequestConfig } from 'axios';
import { User } from 'oidc-client-ts';

import { getApiHost } from 'services';

export interface IRequest {
  url: string;
  body?: any;
  config?: AxiosRequestConfig;
  params?: any;
}

Axios.interceptors.request.use(config => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_SOTER_AUTHORITY}:${process.env.REACT_APP_SOTER_CLIENT_ID}`,
  );

  if (!oidcStorage) {
    return config;
  }

  const { access_token: token } = User.fromStorageString(oidcStorage);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

Axios.interceptors.response.use(
  response => response,
  error => {
    switch (error?.response?.status) {
      case 401:
        window.location.replace('/unauthorized');
        break;
      case 403:
        window.location.replace('/forbidden');
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('Unknown Error:', error);
    }

    return Promise.reject(error);
  },
);

const Api = {
  get: ({ url, params, config }: IRequest): Promise<any> =>
    Axios.get(`${getApiHost()}${url}`, { params, ...config }),

  post: ({ url, body, config }: IRequest): Promise<any> =>
    Axios.post(`${getApiHost()}${url}`, body, config),

  put: ({ url, body, config }: IRequest): Promise<any> =>
    Axios.put(`${getApiHost()}${url}`, body, config),

  delete: ({ url, params, config }: IRequest): Promise<any> =>
    Axios.delete(`${getApiHost()}${url}`, { params, ...config }),
};

export default Api;
