/* eslint-disable no-console */
import Api from 'clients/api/Api';

import {
  ExportOrderParams,
  GetOrderParams,
  GetOrderValues,
  OrderV2,
  RemoveOrderParams,
  UpdateOrdersStatusParams,
} from 'domains/order';

const getOrdersData = async ({
  searchFilter = '',
  status = '',
  plantId = '',
  createdAt = '',
  currentPage = 1,
  pageSize = 20,
}: GetOrderParams): Promise<GetOrderValues> => {
  return Api.get({
    url: '/Order',
    params: {
      searchFilter,
      status,
      plantId,
      createdAt,
      currentPage,
      pageSize,
    },
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const getOrderByIdData = async (orderId: string): Promise<OrderV2> => {
  return Api.get({
    url: `/Order/${orderId}`,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

async function createOrders(orders) {
  return Api.post({ url: '/Order', body: orders })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
}

const updateOrder = async (data, id) => {
  return Api.put({
    url: `/Order/${id}`,
    body: data,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const removeOrder = async ({ id }: RemoveOrderParams): Promise<GetOrderValues> => {
  return Api.delete({
    url: `/Order/${id}`,
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const updateOrdersStatus = async ({
  ordersIDs,
  action,
}: UpdateOrdersStatusParams): Promise<GetOrderValues> => {
  return Api.post({
    url: `/Order/${action}`,
    body: {
      ordersIDs,
    },
  })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

const exportOrdersData = async ({
  searchFilter = null,
  status = null,
  plantId = null,
  createdAt = null,
}: ExportOrderParams) => {
  return Api.post({
    url: '/Order/export',
    body: {
      searchFilter: searchFilter || null,
      status: status || null,
      plantId: plantId || null,
      createdAt: createdAt || null,
    },
  })
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
};

const importOrderTemplate = async file => {
  return Api.post({
    url: '/Order/Import/OrderProducts',
    body: file,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => {
      return response;
    })
    .catch(err => {
      return { message: err?.message };
    });
};

export const OrderService = {
  getOrdersData,
  getOrderByIdData,
  createOrders,
  removeOrder,
  updateOrdersStatus,
  updateOrder,
  exportOrdersData,
  importOrderTemplate,
};
