import { createTheme } from '@mui/material';

import { colorTheme } from './colors';
import { typographyTheme } from './typography';

export const theme = createTheme({
  ...typographyTheme,
  palette: colorTheme.palette,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          padding: 0,
          margin: 0,
          boxSizing: 'border-box',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: '14px',
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          fontSize: '14px',
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          fontSize: '14px',
        },
      },
    },
  },
});
