/* eslint-disable import/no-extraneous-dependencies */
import crypto from 'crypto-js';

import { LocalStorage, SOTER_STORAGE_KEY } from 'utils/storage/localStorage';

import { User, UserRoles } from 'domains/user';

export const HASH_KEY = '@Westrock.Sheetplant.Portal.1693578314';

const iv = crypto.enc.Base64.parse('');
const key = crypto.SHA256(HASH_KEY);

function encryptRole(role: string) {
  const data = role.slice();

  const encryptedRole = crypto.AES.encrypt(data, key, {
    iv,
    mode: crypto.mode.CBC,
    padding: crypto.pad.Pkcs7,
  });

  return encryptedRole.toString();
}

function decryptRole(encryptedRole: string) {
  const decryptedRole = crypto.AES.decrypt(encryptedRole, key, {
    iv,
    mode: crypto.mode.CBC,
    padding: crypto.pad.Pkcs7,
  });

  return decryptedRole.toString(crypto.enc.Utf8);
}

const verifyRole = (role: UserRoles): boolean => {
  const westrockSheetplantUser = LocalStorage.getValueOf(`${SOTER_STORAGE_KEY}:User`) as User;

  if (!westrockSheetplantUser || !westrockSheetplantUser.role) return false;

  return westrockSheetplantUser.role === role.toString();
};

export const RoleHash = {
  encryptRole,
  decryptRole,
  verifyRole,
};
