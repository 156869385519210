export const colorTheme = {
  palette: {
    primary: {
      main: '#0085D4',
      light: '#67C0F5',
      dark: '#003F71',
      100: '#ECF5FF',
      200: '#000000',
      300: '#F7F7F7',
      400: '#D1D1D1',
    },
    secondary: {
      main: '#FF8200',
      100: '#686868',
      200: 'rgb(236, 245, 255)',
      300: '#FF9E3B',
    },
    error: {
      500: '#D91717',
    },
    success: {
      500: '#14B339',
    },
    warning: {
      500: '#E67A00',
    },
    info: {
      500: '#17305A',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F8F9FA',
      clean: '#808080',
    },
    text: {
      primary: '#000000DE',
      disabled: '#585757',
    },
    common: {
      black: '#1E1E1E',
      white: '#FFFFFF',
    },
  },
};
